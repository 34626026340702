























































import { IOption } from "@interface/common.interface";
import { ResponseListMasterType } from "@interface/master.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { masterTypeService } from "@service/master-type.service";
import Vue from "vue";
import { QC_CONDITION } from "@enum/qc-condition.enum";
import MNotificationVue from "@/mixins/MNotification.vue";
import vClickOutside from "v-click-outside";
export default Vue.extend({
  name: "CSelectCondition",
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  mixins: [
    MNotificationVue
  ],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      loader: false,
      toggleOpen: false,
      vmInput: "",
      dtOpt: [] as IOption[],
      focusInput: false,
    };
  },
  mounted() {
    this.getListQC();
  },
  methods: {
    onClickOutside(): void {
      if (!this.focusInput) {
        this.toggleOpen = false;
        this.focusInput = false;
      }
    },
    getMasterType(params: RequestQueryParamsModel): Promise<ResponseListMasterType[]> {
      return masterTypeService.listMaster(params);
    },
    onselectCondition(e: string): void {
      const ref = this.$refs.selectCondition as any;
      this.toggleOpen = false;
      this.$emit("input", e);
      this.$emit("on-select", e);
      ref.blur();
    },
    addNewOption(): void {
      this.dtOpt.push({key: this.vmInput, value: this.vmInput});
      this.vmInput = "";
    },
    onfocus(): void {
      this.toggleOpen = true;
    },
    onclickOption(): void {
      const ref = this.$refs.selectCondition as any;
      ref.focus();
      this.toggleOpen = false;
      ref.blur();
    },
    onchange(e): void {
      const ref = this.$refs.selectCondition as any;
      this.$emit("input", e);
      this.toggleOpen = false;
      ref.blur();
    },
    mapPriorityQc(value: string): number {
      if (value === QC_CONDITION.TO_BE_CONFIRMED) return 1;
      if (value === QC_CONDITION.COLOR_DEFECT) return 2;
      if (value === QC_CONDITION.ODOR_DEFECT) return 3;
      if (value === QC_CONDITION.PACKAGING_DEFECT) return 4;
      if (value === QC_CONDITION.COULD_NOT_BE_USED) return 5;
      return 0;
    },
    async getListQC(): Promise<void> {
      try {
        this.loader = true;
        const listqc = await this.getMasterType({name: "QC_CONDITION"});
        const sortedQc = listqc.map(x => {
          return {
            key: x.id, value: x.value,
            no: this.mapPriorityQc(x.value)
          };
        });
        this.dtOpt = sortedQc.map(x => {
          return { key: x.key, value: x.value };
        });
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loader = false;
      }
    },
  }
});

