var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside"
        }
      ],
      ref: "selectCondition",
      staticStyle: { width: "100%" },
      attrs: {
        value: _vm.value,
        placeholder: _vm.$t("lbl_choose"),
        disabled: _vm.disabled,
        loading: _vm.loader,
        "dropdown-match-select-width": false,
        open: _vm.toggleOpen
      },
      on: {
        select: function(e) {
          return _vm.onselectCondition(e)
        },
        change: function(e) {
          return _vm.onchange(e)
        },
        focus: _vm.onfocus
      },
      scopedSlots: _vm._u([
        {
          key: "dropdownRender",
          fn: function(menu) {
            return [
              _c("v-nodes", { attrs: { vnodes: menu } }),
              _c("a-divider", { staticClass: "my-1" }),
              _c(
                "div",
                { staticClass: "my-1" },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder: _vm.$t("lbl_type_here"),
                      "allow-clear": ""
                    },
                    on: {
                      focus: function($event) {
                        _vm.focusInput = true
                      },
                      blur: function($event) {
                        _vm.focusInput = false
                      }
                    },
                    model: {
                      value: _vm.vmInput,
                      callback: function($$v) {
                        _vm.vmInput = $$v
                      },
                      expression: "vmInput"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-button",
                {
                  staticStyle: { cursor: "pointer" },
                  attrs: { block: "", icon: "plus" },
                  on: {
                    mousedown: function(e) {
                      return e.preventDefault()
                    },
                    click: _vm.addNewOption
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_add_option")) + " ")]
              )
            ]
          }
        }
      ])
    },
    _vm._l(_vm.dtOpt, function(item) {
      return _c(
        "a-select-option",
        {
          key: item.key,
          attrs: { value: item.value },
          on: { click: _vm.onclickOption }
        },
        [
          _c(
            "a-tooltip",
            [
              _c("template", { slot: "title" }, [
                _vm._v(" " + _vm._s(item.value || "-") + " ")
              ]),
              _vm._v(" " + _vm._s(item.value || "-") + " ")
            ],
            2
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }